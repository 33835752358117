const titleTextOptions = {fontSize: '38px', fill: '#ffffff'}
const headerTextOptions = {fontSize: '30px', fill: '#ffffff'}
const header2TextOptions = {fontSize: '28px', fill: '#ffffff'}
const bodyTextOptions = {fontSize: '24px', fill: '#ffffff'}


module.exports = {
    TITLE_TEXT: titleTextOptions,
    HEADER_TEXT: headerTextOptions,
    HEADER2_TEXT: header2TextOptions,
    BODY_TEXT: bodyTextOptions
}