import CoinSpriteSheet from "../assets/coin_Sheet.png";
import BackgroundMusic from "../assets/audio/mixkit-forever-in-my-heart-797.mp3";
import MoneyBagAudio from "../assets/audio/mixkit-money-bag-drop-1989.wav";
import CoinsSoundAudio from "../assets/audio/mixkit-coins-sound-2003.wav";
import SeaWaterSplashAudio from "../assets/audio/mixkit-sea-water-splash-1198.wav";
import RelaxingBellAudio from "../assets/audio/mixkit-relaxing-bell-chime-3109.wav";
import SeaWavesLoopAudio from "../assets/audio/mixkit-sea-waves-loop-1196.wav";

import Weather from "../utils/weather";
import Day from "../utils/day";
import {BODY_TEXT, HEADER2_TEXT, HEADER_TEXT, TITLE_TEXT} from "../utils/style";
import {MIN_POTS, POT_COST, START_BALANCE} from "../constants";

const Phaser = require('phaser');


export default class GameScene extends Phaser.Scene {
    constructor() {
        super({key: 'GameScene'});

        this.cashBalance = START_BALANCE;
        this.potsInshore = MIN_POTS;
        this.potsOffshore = 0;
        this.weather = new Weather();
        this.day = new Day();
    }

    preload() {
        // Preload game assets (e.g., images, sounds) here
        this.load.spritesheet('coin', CoinSpriteSheet, {frameWidth: 16, frameHeight: 16});
        this.load.audio('background', BackgroundMusic);
        this.load.audio('money-bag', MoneyBagAudio);
        this.load.audio('coins-sound', CoinsSoundAudio);
        this.load.audio('splash', SeaWaterSplashAudio);
        this.load.audio('ship-bell', RelaxingBellAudio);
        this.load.audio('sea-waves-loop', SeaWavesLoopAudio);
    }

    create() {
        this.backgroundAudio = this.sound.add('background');
        this.backgroundAudio.play({
            loop: true
        });

        this.seaWavesAudioLoop = this.sound.add('sea-waves-loop');
        this.seaWavesAudioLoop.play({
            loop: true
        });

        this.backgroundAudio.volume = 0.5;
        this.seaWavesAudioLoop.volume = 0.3;

        this.updatePotsDisplay = () => {
            this.potsInshoreText.setText(this.potsInshore);
            this.potsOffshoreText.setText(this.potsOffshore);
        }

        this.handleAddInshore = () => {

            if (this.potsOffshore > 0) {
                this.potsInshore += 1;
                this.potsOffshore -= 1;
            }

            this.updatePotsDisplay();
        }
        this.handleAddOffshore = () => {

            if (this.potsInshore > MIN_POTS) {
                this.potsInshore -= 1;
                this.potsOffshore += 1;
                this.sound.play('splash', {start: 0.5, duration: 1.0,})
            }

            this.updatePotsDisplay();
        }

        this.endWeek = () => {
            // Calculate the total profit and display the end-of-week summary
            const totalProfit = this.cashBalance;
            // You can add code here to display the end-of-week summary in the UI
        }
        this.showScoreWindow = (weather, inPots, offPots, lostPots, profit) => {
            // Implementation of the score window display logic
            // console.log('Active')
            // console.log(this.scene.isActive('ScoreWindow'));

            if (this.scene.isSleeping('ScoreWindow')) {
                this.scene.wake('ScoreWindow', {weather: weather, in: inPots, off: offPots, lost: lostPots, profit: profit});
            } else {
                if (this.scene.isActive('ScoreWindow')) {
                    return;
                }
                this.scene.launch('ScoreWindow');
                this.scene.sleep('ScoreWindow');
                this.scene.wake('ScoreWindow', {weather: weather, in: inPots, off: offPots, lost: lostPots, profit: profit});
            }
        };

        // Create game objects and UI elements


        let titleText = this.add.text(400, 32, "Lobster Lagoon", TITLE_TEXT);
        titleText.setOrigin(0.5, 0.5);

        // Display initial game state (cashBalance, potsOnshore, potsOffshore, day, etc.)
        this.cashText = this.add.text(766, 24, this.cashBalance, HEADER2_TEXT);
        this.cashText.setOrigin(1, 0.5);
        let coinImage = this.add.image(784, 24, 'coin', 0);
        coinImage.setOrigin(1, 0.5);


        this.buyPotButton = this.add.text(766, 54, 'Buy', HEADER2_TEXT);
        this.buyPotButton.setOrigin(1, 0.5);
        this.buyPotButton.setInteractive();
        this.buyPotButton.on('pointerdown', this.handleBuyPot);

        this.buyAllPotsButton = this.add.text(766, 84, 'Buy All', HEADER2_TEXT);
        this.buyAllPotsButton.setOrigin(1, 0.5);
        this.buyAllPotsButton.setInteractive();
        this.buyAllPotsButton.on('pointerdown', this.handleBuyAllPots);

        this.dayText = this.add.text(16, 60, 'Day: ' + this.day, BODY_TEXT);
        this.add.text(16, 100, 'Weather Forecast:', BODY_TEXT);
        this.weatherText = this.add.text(270, 100, this.weather, BODY_TEXT);
        this.add.text(16, 140, "Pots", HEADER_TEXT);
        this.add.text(16, 180, 'Inshore:', BODY_TEXT);
        this.potsInshoreText = this.add.text(140, 180, this.potsInshore, BODY_TEXT);
        this.add.text(16, 220, 'Offshore:', BODY_TEXT);
        this.potsOffshoreText = this.add.text(155, 220, this.potsOffshore, BODY_TEXT);

        this.addInshoreButton = this.add.text(210, 180, '+', BODY_TEXT);
        this.addInshoreButton.setInteractive();
        this.addInshoreButton.on('pointerdown', this.handleAddInshore);

        this.addOffshoreButton = this.add.text(210, 220, '+', BODY_TEXT);
        this.addOffshoreButton.setInteractive();
        this.addOffshoreButton.on('pointerdown', this.handleAddOffshore);

        let nextDayButton = this.add.text(this.game.config.width / 2, this.game.config.height - 80, 'Next Day', TITLE_TEXT).setOrigin(0.5, 0.5).setInteractive();
        nextDayButton.on('pointerdown', this.handleNextDayButtonClick);

        let muteButton = this.add.text(this.game.config.width - 15, this.game.config.height - 15, 'Mute', BODY_TEXT).setOrigin(1.0, 0.5).setInteractive();
        muteButton.on('pointerdown', this.handleMuteClick);

        this.scoreUpdateTween = this.tweens.addCounter({
            from: START_BALANCE,
            to: START_BALANCE,
            duration: 2000,
            ease: 'linear',
            onUpdate: tween => {
                const value = Math.round(tween.getValue());
                this.cashText.setText(value);
            }
        });
    }

    update(time, delta) {
        // Check for user interactions and game logic updates here
        // Implement the weather simulation, daily profit calculation, and player actions
        // Update the game state and UI elements accordingly
    }

    updateBalance = (oldBalance, newBalance) => {

        let duration;

        if (newBalance > oldBalance) {
            duration = (newBalance - oldBalance) * 30;
        } else {
            duration = (oldBalance - newBalance) * 30;
        }

        if (duration > 2000) {
            duration = 2000;
        }

        if (this.scoreUpdateTween.isPlaying()) {
            //  The tween is already running, so we'll update the end value with resetting it
            this.scoreUpdateTween.updateTo('value', newBalance);
        } else {
            //  The tween has finished, so create a new one
            this.scoreUpdateTween = this.tweens.addCounter({
                from: oldBalance,
                to: newBalance,
                duration: duration,
                ease: 'linear',
                onUpdate: tween => {
                    const value = Math.round(tween.getValue());
                    this.cashText.setText(value);
                }
            });
        }
    }

    buyPots = (numberOfPots) => {
        if (numberOfPots === 0) return;
        if (this.cashBalance < (numberOfPots * POT_COST)) return;

        this.sound.play('coins-sound');
        let oldBalance = this.cashBalance;
        this.cashBalance -= (numberOfPots * POT_COST);
        this.potsInshore += numberOfPots;
        this.updateBalance(oldBalance, this.cashBalance);
        this.updatePotsDisplay();

    }

    handleNextDayButtonClick = () => {
        // Check if the week has ended
        if (this.day.isEndOfWeek()) {
            this.endWeek();
            return;
        }

        this.weather.resolve();

        let lostPots = 0;
        let oldBalance = this.cashBalance;

        if (this.weather.isGood()) {
            // On a good weather day, place pots onshore and update game state
            this.cashBalance += (this.potsInshore * 2) + (this.potsOffshore * 6); // Onshore pots are worth £2 in good weather

            this.potsInshore += this.potsOffshore;
            this.potsOffshore = 0;
        } else {
            // On a bad weather day, collect pots offshore and update game state
            this.cashBalance += this.potsInshore * 4; // Inshore pots are worth £4 in bad weather

            lostPots = this.potsOffshore;
            this.potsOffshore = 0;
        }

        this.updateBalance(oldBalance, this.cashBalance);

        this.sound.play('ship-bell');
        this.showScoreWindow(this.weather, this.potsInshore, this.potsOffshore, lostPots, this.cashBalance-oldBalance);

        // Progress to the next day
        this.day.nextDay();

        // Update forecast
        this.weatherText.setText(this.weather.getPrediction());

        // Update UI elements with the latest game state
        this.dayText.setText('Day: ' + this.day);
        this.updatePotsDisplay();
    }

    handleBuyPot = () => {
        this.buyPots(1)
    }

    handleBuyAllPots = () => {
        this.buyPots(Math.floor(this.cashBalance / POT_COST));
    }

    handleMuteClick = () => {
        // this.backgroundAudio.mute = !this.backgroundAudio.mute;
        // this.seaWavesAudioLoop.mute = !this.seaWavesAudioLoop.mute;
        this.sound.mute = !this.sound.mute;
    }

}