import GameScene from "./scenes/game-scene";
import ScoreWindow from './score-window.js';

const Phaser = require('phaser');

// game.js
const config = {
  type: Phaser.AUTO,
  width: 800,
  height: 600,
  scene: [
      GameScene,
      ScoreWindow,
  ]
};

// const config = {
//   type: Phaser.AUTO,
//   scale: {
//         mode: Phaser.Scale.FIT,
//         parent: 'phaser-example',
//         autoCenter: Phaser.Scale.CENTER_BOTH,
//         width: 800,
//         height: 600
//     },
//   scene: [
//       new GameScene(),
//       new ScoreWindow({ key: 'ScoreWindow'}),
//   ]
// };

const game = new Phaser.Game(config);