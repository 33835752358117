import {BODY_TEXT, HEADER2_TEXT, HEADER_TEXT, TITLE_TEXT} from "./utils/style";
import CoinSpriteSheet from "./assets/coin_Sheet.png";

const Phaser = require('phaser');

export default class ScoreWindow extends Phaser.Scene {
    constructor() {
        super({key: 'ScoreWindow', active: false});

        this.score = 0;
    }

    preload() {
        this.load.spritesheet('coin', CoinSpriteSheet, {frameWidth: 16, frameHeight: 16});
    }

    create() {
        // Add code to create the score window elements
        this.add.rectangle(this.game.config.width / 2, this.game.config.height / 2, this.game.config.width - 50, this.game.config.height - 50, 0x2c2c2c);

        // this.text = this.add.text(320, 300, '').setAlign('center').setOrigin(0.5, 0);
        // this.text.setInteractive();
        // this.text.on('pointerdown', () => {
        //     this.scene.sleep();
        // });

        this.profitText = this.add.text(this.game.config.width / 2, 200, '', TITLE_TEXT).setAlign('center').setOrigin(1, 0.5);

        const spinConfig = {
            key: 'spin',
            frames: 'coin',
            frameRate: 80,
            repeat: 5
        };
        this.anims.create(spinConfig);
        this.coinSprite = this.add.sprite((this.game.config.width / 2) + 5, 200, 'coin').setOrigin(0, 0.5).play('spin');
        this.coinSprite.setDisplaySize(50,50);


        this.remainingInshoreText = this.add.text((this.game.config.width / 2)-50, 300, '', HEADER_TEXT).setAlign('center').setOrigin(0.5, 0);
        this.remainingOffshoreText = this.add.text((this.game.config.width / 2)+50, 300, '', HEADER_TEXT).setAlign('center').setOrigin(0.5, 0);

        this.weatherDescriptionText = this.add.text(this.game.config.width / 2, 370, '', BODY_TEXT).setAlign('center').setOrigin(0.5, 0);

        let doneButton = this.add.text(this.game.config.width / 2, this.game.config.height - 80, 'Done', TITLE_TEXT).setAlign('center').setOrigin(0.5, 0).setInteractive();
        doneButton.on('pointerdown', () => this.scene.sleep());

        this.events.on('wake', this.setScore, this);
    }

    update(time, delta) {
        // super.update(time, delta);
        // this.score += 1;
        // this.text.setText('Score:\n\n' + this.score);
    }

    setScore(sys, data) {
        let weather = data.weather;
        let inPots = data.in;
        let offPots = data.off;
        let lostPots = data.lost;
        let profit = data.profit;

        console.log(`In: ${inPots}\nOff: ${offPots}\nLost: ${lostPots}\nProfit: ${profit}`);

        this.profitText.setText(`+${profit}`);

        this.remainingInshoreText.setText(inPots);

        if (lostPots) {
            this.remainingOffshoreText.setText(lostPots * -1);
        } else {
            this.remainingOffshoreText.setText(offPots);
        }

        this.weatherDescriptionText.setText(weather.description());

        this.coinSprite.play('spin');
    }
}