let days = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday"
}

export default class Day {
    constructor() {
        this.day = 1;
    }

    toString() {
        return days[this.day];
    }

    nextDay() {
        if (this.day >= 7) return false; // No progression - end of the week.

        // Progress to the next day
        this.day += 1;
        return true;
    }

    isEndOfWeek() {
        return (this.day >= 7);
    }
}